import img0 from "../../assets/refribekFotos/f0.jpeg";
import img1 from "../../assets/refribekFotos/f1.jpeg";
import img2 from "../../assets/refribekFotos/f2.jpeg";
import img3 from "../../assets/refribekFotos/f3.jpeg";
import img4 from "../../assets/refribekFotos/f4.jpeg";
import img5 from "../../assets/refribekFotos/f5.jpeg";
import img6 from "../../assets/refribekFotos/f6.jpeg";
import img7 from "../../assets/refribekFotos/f7.jpeg";
import img8 from "../../assets/refribekFotos/f8.jpeg";
import img9 from "../../assets/refribekFotos/f9.jpeg";
import img10 from "../../assets/refribekFotos/f10.jpeg";

const dataImg = [
  {
    photo: img0,
  },
  {
    photo: img1,
  },
  {
    photo: img2,
  },
  {
    photo: img3,
  },
  {
    photo: img4,
  },
  {
    photo: img5,
  },
  {
    photo: img6,
  },
  {
    photo: img7,
  },
  {
    photo: img8,
  },
  {
    photo: img9,
  },
  {
    photo: img10,
  },
  /*  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/IMG_20180329_110204331-2-1024x576.jpg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2018-08-10-at-21.33.32-1024x576.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2018-08-10-at-21.33.301-576x1024.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2018-08-10-at-21.33.29-1024x576.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2017-12-10-at-10.03.45-PM-1024x614.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2017-12-10-at-10.03.44-PM-1-1024x576.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2017-12-10-at-10.04.46-PM-1024x576.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2017-12-26-at-12.22.57-PM-1024x576.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2017-11-27-at-6.49.15-PM-1024x576.jpeg",
  },
  {
    photo:
      "http://www.refribek.com.br/wp-content/uploads/2017/09/WhatsApp-Image-2017-11-04-at-5.45.17-PM-1024x576.jpeg",
  }, */
];

export { dataImg };
